import BridgeService from 'common/services/bridgeService';

class LifeticketCancellationReceiptAnalytics {
  private static eventName = 'CANCEL_SUCESSO';

  static receiptResult(
    screen: string,
    ref_type: string,
    action_id: string,
    value: string,
    productName: string,
  ) {
    BridgeService.requestAnalytics(
      `${LifeticketCancellationReceiptAnalytics.name}.${LifeticketCancellationReceiptAnalytics.receiptResult.name}`,
      LifeticketCancellationReceiptAnalytics.eventName,
      {
        screen,
        ref_type,
        content_action: 'dado carregado',
        action_id,
        value,
        product: productName,
      },
    );
  }
}
export default LifeticketCancellationReceiptAnalytics;
