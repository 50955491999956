import { styled } from '@linaria/react';

export const Title = styled.div`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: var(--gray500);
  margin: 0 0 8px;
`;

export const Description = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--gray400);
`;

export const SubTitle = styled.div`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: var(--gray500);
  margin: 0 0 16px;
`;

export const Label = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: var(--gray400);
  margin: 0 0 16px;
`;

export const Value = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: var(--gray500);
  margin: 0 0 16px;
`;

export const Recommendation = styled.div`
  color: var(--gray500);
  margin: 0 0 9px;
  width: 261px;
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 24px 0 0;
  gap: 10px;
`;

export const Circle = styled.div<{ color?: string }>`
  padding: 16px;
  width: 64px;
  height: 64px;
  border-radius: 60px;
  background: ${(props) => props.color || 'var(--success500)'};
`;
