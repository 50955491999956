import { MockService } from 'common/services/mockService';
import { MultiplatformHttpService } from 'common/services/multiplatformHttpService';
import { EnvVariableService } from '../../../../common/services/envService';
import { EnvVariableKeys } from '../../../../common/services/envService/types';
import { CancellationResponse } from './types';

// This service should be call to cancel REQUESTED insurances only.
// ACTIVE insurances will no use this service (cancelation will bem processed by Central de Assinaturas)
class LifeticketCancellationService {
  static async requestCancellation(id: string): Promise<CancellationResponse> {
    const APP_API_CANCEL = EnvVariableService.getVariable(
      EnvVariableKeys.APP_API_REQUESTED_LIFETICKET_CANCELLATION_PATH,
    );

    const APP_API_BRIDGE_CANCEL = EnvVariableService.getVariable(
      EnvVariableKeys.APP_API_REQUESTED_LIFETICKET_BRIDGE_CANCELLATION_PATH,
    );
    const response =
      await MultiplatformHttpService.doExecute<CancellationResponse>({
        urlPrefix: MockService.shouldMock ? MockService.baseUrl : undefined,
        method: 'POST',
        endpoint: {
          browser: APP_API_CANCEL.replace(/{id}/, `${id}`),
          bridge: APP_API_BRIDGE_CANCEL.replace(/{id}/, `${id}`),
        },
        headers: {
          browser: {
            'x-api-key': MockService.apiKey,
            'x-inter-conta': MockService.account,
          },
          bridge: {},
        },
      });
    return response.data;
  }
}

export default LifeticketCancellationService;
