import { MultiplatformHttpRequestConfig } from 'common/services/multiplatformHttpService/types';
import { MonitoringService } from 'common/services/monitoringService';
import { IWbHttpData } from '@interco/inter-webview-bridge';

export function logCallByBridgeRequest(
  request: MultiplatformHttpRequestConfig,
): void {
  MonitoringService.log('BaseService.callByBridge.request', request);
}

export function logCallByBridgeResponse(response: IWbHttpData): void {
  MonitoringService.log('BaseService.callByBridge.response', response);
}
