import React, { useEffect } from 'react';
import useBoundState from 'store';
import { Div } from '../../../styles/commons';

const ErrorObserver: React.FC = () => {
  const { hasError, errorInstance } = useBoundState((state) => state.error);

  useEffect(() => {
    if (hasError) {
      throw errorInstance;
    }
  }, [errorInstance, hasError]);

  return <Div />;
};

export default ErrorObserver;
