import { EnvVariableService } from 'common/services/envService';
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BrowserHttpService } from 'common/services/browserHttpService';
import { ServiceError } from 'common/exceptions';
import {
  logCallByBridgeRequest,
  logCallByBridgeResponse,
} from 'common/loggers/baseServiceLogger';
import { interWbHttp, IWbHttpData } from '@interco/inter-webview-bridge';
import { ErrorResponse } from 'store/error/types';
import BridgeService from '../bridgeService';
import { MonitoringService } from '../monitoringService';
import { MultiplatformHttpRequestConfig, ServiceResponse } from './types';
import { EnvVariableKeys } from '../envService/types';

const STATUS_CODE_ACCEPTED = [400, 404];
const BRIDGE_MESSAGES_ACCEPTED = [
  'Parece que você está sem internet ou sua conexão encontra-se instável.',
];

export class MultiplatformHttpService {
  static async callByBrowser<T>(
    config: MultiplatformHttpRequestConfig,
  ): Promise<ServiceResponse<T>> {
    const { method, endpoint, headers, data, params, urlPrefix } = config;
    const response = await BrowserHttpService.request(endpoint.browser, {
      method,
      baseURL: urlPrefix,
      headers: headers.browser,
      params,
      body: data ? JSON.stringify(data) : undefined,
    });

    const headersResponse: Record<string, string> = {};

    Object.keys(response.headers).forEach((key) => {
      headersResponse[key] = response.headers.get(key) || '';
    });

    if (!response.ok) {
      let responseError = undefined;
      try {
        responseError = await response.json();
      } catch {}

      throw new ServiceError(
        STATUS_CODE_ACCEPTED.includes(response.status),
        responseError,
        'Something went wrong in the request, see the data object for more details.',
        response.status,
        headersResponse,
      );
    }

    return {
      headers: headersResponse,
      status: response.status,
      data: (await response.json()) as T,
    } as ServiceResponse<T>;
  }

  static async callByBridge<T>(
    requestConfig: MultiplatformHttpRequestConfig,
  ): Promise<ServiceResponse<T>> {
    logCallByBridgeRequest(requestConfig);

    const { endpoint, headers, data } = requestConfig;

    const method = requestConfig.method.toLowerCase();

    let bridgeResponse = {} as IWbHttpData;

    if (headers && headers.bridge) {
      headers.bridge['x-inter-frontend-session'] = String(
        MonitoringService.frontendSession,
      );
    }

    const headersWithSession =
      headers && headers.bridge ? headers.bridge : undefined;

    try {
      switch (method) {
        case 'get': {
          bridgeResponse = await interWbHttp.get(
            endpoint.bridge,
            headersWithSession,
          );
          break;
        }
        case 'post': {
          bridgeResponse = await interWbHttp.post(
            endpoint.bridge,
            data,
            headersWithSession,
          );
          break;
        }
        case 'put': {
          bridgeResponse = await interWbHttp.put(
            endpoint.bridge,
            data,
            headersWithSession,
          );
          break;
        }
        case 'delete': {
          bridgeResponse = await interWbHttp.delete(
            endpoint.bridge,
            headersWithSession,
          );
          break;
        }
      }
    } catch (error: any) {
      let errorObject: any = error;
      if (typeof errorObject === 'string') {
        try {
          errorObject = JSON.parse(error);
        } catch {}
      }

      MultiplatformHttpService.handleBackendErrors(errorObject);
      MultiplatformHttpService.handleAcceptableErrorMessages(errorObject);

      throw error;
    }

    logCallByBridgeResponse(bridgeResponse);

    let parsedData = bridgeResponse.response as any;

    if (typeof parsedData === 'string') {
      try {
        parsedData = JSON.parse(parsedData);
      } catch (e: any) {
        MonitoringService.noticeError(e, {
          errorCodeRef:
            'MultiplatformHttpService.callByBridge.parseResponseToJSON',
        });
      }
    }

    return {
      data: parsedData,
      status: bridgeResponse.httpStatus,
      headers: bridgeResponse.headers,
    };
  }

  static handleBackendErrors(errorObject: any): void {
    if (errorObject.httpStatus) {
      let errorResponse: any = errorObject.response;

      if (typeof errorObject.response === 'string') {
        try {
          errorResponse = JSON.parse(errorObject.response);
        } catch {}
      }

      throw new ServiceError(
        STATUS_CODE_ACCEPTED.includes(errorObject.httpStatus),
        errorResponse,
        JSON.stringify(errorResponse),
        errorObject.httpStatus,
      );
    }
  }

  static handleAcceptableErrorMessages(errorObject: any) {
    if (
      errorObject.message &&
      BRIDGE_MESSAGES_ACCEPTED.find((message) =>
        errorObject.message.includes(message),
      )
    ) {
      const errorResponse: ErrorResponse = {
        errors: [{ code: errorObject.action, message: errorObject.message }],
        totalErrors: 1,
      };
      throw new ServiceError(
        true,
        errorResponse,
        JSON.stringify(errorResponse),
        errorObject.httpStatus,
      );
    }
  }

  static async doExecute<T>(
    config: MultiplatformHttpRequestConfig,
  ): Promise<ServiceResponse<T>> {
    if (BridgeService.isBrowser()) {
      const INTER_ENV = EnvVariableService.getVariable(
        EnvVariableKeys.INTER_ENV,
      );
      if (INTER_ENV === 'production') {
        return Promise.reject(
          new Error(
            'Não é possível executar ambiente de produção via browser.',
          ),
        );
      }

      return MultiplatformHttpService.callByBrowser(config);
    }

    return MultiplatformHttpService.callByBridge(config);
  }
}
