export type AffinitiesState = {
  affinities: AffinitiesObject;
} & AffinitiesActions;

export interface AffinitiesObject {
  code: string;
  contractedPlan: string;
  planPrice: string;
  paymentMeans: string;
  productType: string;
  cancellation: AffinitiesCancellationFlow;
}

export interface AffinitiesActions {
  setAffinitiesObject: (affinities: AffinitiesObject) => void;
  setAffinitiesCancellation: (
    affinitiesCancellation: AffinitiesCancellationFlow,
  ) => void;
  setAffinitiesCancellationReasonCode: (cancellationReasonCode: number) => void;
  setAffinitiesCancellationReasons: (
    cancellationReasons: AffinitiesCancellationReason[],
  ) => void;
  setAffinitiesCancellationTerms: (
    isTermsAcnConditionsAccepted: boolean,
  ) => void;
  setAffinitiesCancellationReceipt: (
    receipt: AffinitiesCancellationResponse,
  ) => void;
  resetAffinities: () => void;
}

export interface AffinitiesCancellationFlow {
  isTermsAndConditionsAccepted: boolean;
  reasonCode: number;
  reasons: AffinitiesCancellationReason[];
  receipt: AffinitiesCancellationResponse;
}

export interface AffinitiesCancellationReason {
  codigo: number;
  descricao: string;
}

export interface AffinitiesCancellationResponse {
  message: string;
  code: number;
  sucesso: boolean;
  mensagem: string;
  protocolo: string;
  title: string;
  subtitle: string;
  status: string;
  titleInfoCancellation: string;
  requestDate: string;
  acomplishmentDate: string;
  protocol: string;
  plan: {
    id: number;
    title: string;
    installmentValue: number;
  };
}

export const INITIAL_STATE: AffinitiesObject = {
  code: '',
  contractedPlan: '',
  paymentMeans: '',
  planPrice: '',
  productType: '',
  cancellation: {
    isTermsAndConditionsAccepted: false,
    reasonCode: 0,
    reasons: [],
    receipt: {
      message: '',
      code: 0,
      sucesso: false,
      mensagem: '',
      protocolo: '',
      title: '',
      subtitle: '',
      status: '',
      titleInfoCancellation: '',
      requestDate: '',
      acomplishmentDate: '',
      protocol: '',
      plan: {
        id: 0,
        title: '',
        installmentValue: 0,
      },
    },
  },
};
