import { EnvVariableService } from 'common/services/envService';
import { TrackableError } from 'common/exceptions';
import { AppInfoService } from '../appInfoService';
import { EnvVariableKeys } from '../envService/types';

const isLocalLogEnabled = () =>
  EnvVariableService.getVariableAsBoolean(EnvVariableKeys.LOCAL_LOG_ENABLED);

const isRemoteLogEnabled = () =>
  EnvVariableService.getVariableAsBoolean(EnvVariableKeys.REMOTE_LOG_ENABLED);

export class MonitoringService {
  private static _frontendSession: number;

  static logAttributeCount = 1;

  static get frontendSession(): number {
    return MonitoringService._frontendSession;
  }

  static setCustomAttribute(name: string, value: string | number): void {
    if (newrelic) {
      newrelic.setCustomAttribute(name, value);
    }
  }

  static noticeError(
    error: Error,
    customAttributes?: { [key: string]: string | number },
  ): void {
    if (isLocalLogEnabled()) {
      console.error(error);
    }

    // always send errors to new relic
    if (newrelic) {
      const trackableError: TrackableError = error;
      let enchancedCustomAttributes = customAttributes;

      if (trackableError?.errorCodeRef) {
        if (!enchancedCustomAttributes) {
          enchancedCustomAttributes = {
            errorCodeRef: trackableError.errorCodeRef,
          };
        } else if (!enchancedCustomAttributes?.errorCodeRef) {
          enchancedCustomAttributes = {
            errorCodeRef: trackableError.errorCodeRef,
            ...enchancedCustomAttributes,
          };
        }
      }

      if (trackableError?.errorLogRef) {
        if (!enchancedCustomAttributes) {
          enchancedCustomAttributes = {
            errorLogRef: trackableError.errorLogRef,
          };
        } else if (!enchancedCustomAttributes?.errorLogRef) {
          enchancedCustomAttributes = {
            errorLogRef: trackableError.errorLogRef,
            ...enchancedCustomAttributes,
          };
        }
      }

      newrelic.noticeError(error, enchancedCustomAttributes);
    }
  }

  static async setDefaultAttributes(account: string): Promise<void> {
    // string is to prevent new relic interpret this field as a timestamp
    MonitoringService._frontendSession = Date.now();
    MonitoringService.setCustomAttribute('bankAccount', account);
    MonitoringService.setCustomAttribute(
      'frontendSession',
      `${MonitoringService._frontendSession}`,
    );
    await AppInfoService.appInfo((e) => {
      MonitoringService.noticeError(e, {
        errorCodeRef: 'MonitoringService.setDefaultAttributes.getAppInfo',
      });
    });

    MonitoringService.setCustomAttribute(
      AppInfoService.DEVICE_NAME_KEY,
      AppInfoService.deviceName,
    );
    MonitoringService.setCustomAttribute(
      AppInfoService.SUPER_APP_VERSION_KEY,
      AppInfoService.superAppVersion,
    );
    MonitoringService.setCustomAttribute(
      AppInfoService.IS_DARK_MODE_KEY,
      String(AppInfoService.isDarkMode),
    );
  }

  static log(logCodeRef: string, logMessage: unknown): void {
    if (isLocalLogEnabled()) {
      console.log({ logCodeRef, logMessage });
    }

    if (isRemoteLogEnabled() && newrelic) {
      newrelic
        .interaction()
        .setAttribute('isLogInteraction', true)
        .setAttribute(
          `log${MonitoringService.logAttributeCount}CodeRef`,
          logCodeRef,
        )
        .setAttribute(
          `log${MonitoringService.logAttributeCount}Message`,
          logMessage,
        )
        .onEnd(() => {
          MonitoringService.logAttributeCount = 1;
        })
        .save();

      MonitoringService.logAttributeCount += 1;
    }
  }

  static forceLog(logCodeRef: string, logMessage: unknown): void {
    if (newrelic) {
      newrelic
        .interaction()
        .setAttribute('isLogInteraction', true)
        .setAttribute(
          `log${MonitoringService.logAttributeCount}CodeRef`,
          logCodeRef,
        )
        .setAttribute(
          `log${MonitoringService.logAttributeCount}Message`,
          logMessage,
        )
        .onEnd(() => {
          MonitoringService.logAttributeCount = 1;
        })
        .save();

      MonitoringService.logAttributeCount += 1;
    }
  }
}
