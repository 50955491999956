import { EnvVariableService } from 'common/services/envService';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  logISafeRequest,
  logUserInfoResponse,
} from 'common/loggers/bridgeServiceLogger';
import InterWebviewBridge, {
  interWbAuth,
  interWbNavigate,
  interWbSession,
  IWbISafeResponse,
  IWbUserInfo,
  WbEnvironments,
  WbSessionActions,
} from '@interco/inter-webview-bridge';
import AppInfoMockService from '../appInfoMockService';
import ISafeTokenMockService from '../iSafeTokenMockService';
import { MockService } from '../mockService';
import { MonitoringService } from '../monitoringService';
import UserInfoMockService from '../userInfoMockService';
import { IRequestISafe, IWbAppInfo } from './types';
import { EnvVariableKeys } from '../envService/types';
import { WouterService } from '../wouterService';
import { MainRoutesInfo } from 'modules/main/routes';

const IS_NOT_RUNNING_PROD = !EnvVariableService.getVariableAsBoolean(
  EnvVariableKeys.INTER_ENV,
  'production',
);

class BridgeService {
  private static interWb = InterWebviewBridge.getInstance();

  private static handleBackButton: (() => void) | undefined;

  private static addUrlProtocol(url: string) {
    const HTTPS_PROTOCOL = 'https://';
    const HTTP_PROTOCOL = 'http://';
    if (url.startsWith(HTTPS_PROTOCOL) || url.startsWith(HTTP_PROTOCOL)) {
      return url;
    } else {
      return HTTPS_PROTOCOL + url;
    }
  }

  static getEnvironment(): WbEnvironments {
    return this.interWb.getEnvironment();
  }

  static isBrowser(): boolean {
    return this.interWb.getEnvironment() === WbEnvironments.BROWSER;
  }

  static enableRemoteBridgeDebugLog(): void {
    this.interWb.setDebug(true);
    (this.interWb as any).info = (
      action: string,
      evitId: string,
      message: string,
    ) => {
      // eslint-disable-next-line no-console
      console?.info(`InterWebviewBridge - ${action}(${evitId}): ${message}`);

      MonitoringService.log(
        'BridgeService.enableRemoteBridgeDebugLog',
        `InterWebviewBridge - ${action}(${evitId}): ${message}`,
      );
    };
  }

  static async getUserInfo(): Promise<IWbUserInfo> {
    let userInfo;

    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      userInfo = await UserInfoMockService.requestUserInfo(MockService.account);
    } else {
      userInfo = await interWbSession.getUserInfo();
    }
    logUserInfoResponse(userInfo);
    return userInfo;
  }

  static async getAppInfo(): Promise<IWbAppInfo> {
    let appInfo;

    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      appInfo = await AppInfoMockService.requestAppInfo();
    } else {
      appInfo = await interWbSession.getAppInfo();
    }
    return appInfo;
  }

  static async getISafeToken(
    requestData: IRequestISafe,
  ): Promise<IWbISafeResponse> {
    logISafeRequest(requestData);

    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      return ISafeTokenMockService.requestISafe(
        requestData.category,
        requestData.checkType,
        requestData.feature,
        requestData.value,
      );
    } else {
      try {
        return await interWbAuth.requestISafe(
          requestData.category,
          requestData.checkType,
          requestData.feature,
          requestData.value,
        );
      } catch (error) {
        MonitoringService.noticeError(error as Error, {
          errorCodeRef: 'BridgeService.getISafeToken.requestISafe',
        });
        return Promise.reject(error);
      }
    }
  }

  static async backToNative(): Promise<void> {
    if (this.isBrowser()) {
      WouterService.navigate(MainRoutesInfo.insurances.path);
    } else {
      await interWbNavigate.requestGoBack();
    }
  }

  static async openDeepLink(url: string): Promise<void> {
    await interWbNavigate.openDeepLink(url);
  }

  static async sharedContentPDF(url: string, title: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(BridgeService.addUrlProtocol(url), '_blank')?.focus();
      return;
    }
    try {
      await this.interWb.execute({
        action: 'sharedContentPDF',
        metadata: {
          url,
          title,
        },
      });
    } catch (e: any) {
      MonitoringService.noticeError(e, {
        errorCodeRef: 'BridgeService.sharedContentPDF.sharedContentPDF',
      });
    }
  }

  static async sharedContentEmail(
    recipient: string,
    subject = 'Coloque seu assunto',
    htmlBody = 'Escreva sua mensagem',
  ): Promise<void> {
    if (this.isBrowser()) {
      const urlMailto =
        'mailto:' + recipient + '&subject=' + subject + '&body=' + htmlBody;
      window.open(urlMailto, '_blank')?.focus();
      return;
    }

    try {
      await this.interWb.execute({
        action: 'sharedContentEmail',
        metadata: {
          subject,
          recipient,
          htmlBody,
        },
      });
    } catch (e: any) {
      MonitoringService.noticeError(e, {
        errorCodeRef: 'BridgeService.sharedContentEmail.sharedContentEmail',
      });
    }
  }

  static async openBrowser(url: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(BridgeService.addUrlProtocol(url), '_blank')?.focus();
      return;
    }

    try {
      await this.interWb.execute({
        action: 'openBrowser',
        metadata: {
          url: BridgeService.addUrlProtocol(url),
        },
      });
    } catch (e: any) {
      MonitoringService.noticeError(e, {
        errorCodeRef: 'BridgeService.openBrowserRequest.openBrowser',
      });
    }
  }

  static async goToAppHomeOrBackToNative(
    navigate: (pathname: string) => void,
  ): Promise<void> {
    if (this.isBrowser()) {
      navigate('/');
      return;
    }

    if (this.interWb.getEnvironment() === WbEnvironments.ANDROID) {
      try {
        await this.interWb.execute({ action: 'navigateToHome' });
      } catch (e: any) {
        MonitoringService.noticeError(e, {
          errorCodeRef:
            'BridgeService.goToAppHomeOrBackToNative.navigateToHome',
        });
        await BridgeService.backToNative();
      }
    }

    if (this.interWb.getEnvironment() === WbEnvironments.IOS) {
      try {
        await this.interWb.execute({ action: 'navigateHome' });
      } catch (e: any) {
        MonitoringService.noticeError(e, {
          errorCodeRef: 'BridgeService.goToAppHomeOrBackToNative.navigateHome',
        });
        await BridgeService.backToNative();
      }
    }
  }

  static requestAnalytics(
    errorCodeRef: string,
    name: string,
    params?: Record<string, string>,
  ): void {
    if (!this.isBrowser()) {
      interWbSession.requestAnalytics(name, params).catch((e) => {
        MonitoringService.noticeError(e, {
          errorCodeRef,
          requestAnalyticsName: name,
          requestAnalyticsParams: params ? JSON.stringify(params) : 'No params',
        });
      });
    }
  }

  static addBackButtonEvent(callback: () => void): void {
    console.log('entrou addBackButtonEvent');
    if (this.handleBackButton) {
      console.log('chamando removeWebviewEventListener');
      this.interWb.removeWebviewEventListener(
        WbSessionActions.GO_BACK,
        this.handleBackButton,
      );
      console.log('completou removeWebviewEventListener');
    }

    this.handleBackButton = callback;
    console.log('chamando addWebviewEventListener');
    this.interWb
      .addWebviewEventListener(WbSessionActions.GO_BACK, this.handleBackButton)
      .then(() => {
        MonitoringService.log(
          'BridgeService.addBackButtonEvent',
          'Completou chamado ao BridgeService.addBackButtonEvent',
        );
      })
      .catch((e) => {
        MonitoringService.noticeError(e, {
          errorCodeRef:
            'BridgeService.addBackButtonEvent.addWebviewEventListener',
        });
      });
  }

  static async callPhoneNumber(phoneNumber: string): Promise<void> {
    const SPACE_STRING_RGEX = /\s+/g;
    const EMPTY_STRING = '';

    try {
      await this.interWb.execute({
        action: 'callPhoneNumber',
        metadata: {
          phoneNumber: phoneNumber.replace(SPACE_STRING_RGEX, EMPTY_STRING),
        },
      });
    } catch (e: any) {
      MonitoringService.noticeError(e, {
        errorCodeRef: 'BridgeService.callPhoneNumber.callPhoneNumber',
      });
    }
  }
}

export default BridgeService;
