import React from 'react';
import useBoundState from 'store';
import { OverlayBlock } from './styles';

const Overlay: React.FC = () => {
  const { isLoading } = useBoundState((state) => state.loading);
  return <OverlayBlock show={isLoading} />;
};

export default Overlay;
