import ArrowLeft from '@interco/icons/orangeds/MD/arrow-left';
import { Button } from '@interco/inter-ui/components/Button';
import { FontSizeREM } from 'common/styles/types';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { EMPTY } from 'routes/types';
import useBoundState from 'store';
import { TrackableError } from '../../../exceptions';
import { Div, Flex, H1, PSmallGray } from '../../../styles/commons';
import { useErrorFallbackConfig } from '../useErrorFallbackConfig';
import { ErrorContainer } from './styles';

const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  const setTitle = useBoundState((state) => state.setNavbarTitle);

  const { arrowLeftState, imgState, buttonState } = useErrorFallbackConfig();

  const {
    errorResponse: { errors },
    isDetailedError,
  } = useBoundState((state) => state.error);

  let frontendLogRef = '';
  frontendLogRef = `${Date.now()}`;
  const trackableError: TrackableError = error;
  trackableError.errorLogRef = frontendLogRef;

  useEffect(() => {
    setTitle(EMPTY);
  }, [setTitle]);

  return (
    <ErrorContainer>
      <Div height="calc(100% - 48px)" position="relative" margin="24px">
        <Div height="56px">
          <ArrowLeft
            data-testid="navbar-back-btn"
            height={24}
            width={24}
            onClick={arrowLeftState.onClick}
          />
        </Div>
        <Flex
          flexDirection="row"
          width="100%"
          justifyContent="center"
          margin="75px 0 24px"
        >
          <Flex
            flexDirection="row"
            width="100%"
            justifyContent="center"
            margin="75px 0 24px"
          >
            <img src={imgState.src} alt={imgState.alt} />
          </Flex>
        </Flex>
        <H1 style={{ textAlign: 'center' }} margin="8px">
          {isDetailedError
            ? 'Algo inesperado ocorreu por aqui'
            : 'Houve um erro por aqui'}
        </H1>
        <PSmallGray
          style={{ textAlign: 'center' }}
          lineHeight={FontSizeREM.PX17}
          margin="16px"
        >
          {errors[0].message}
        </PSmallGray>
        {frontendLogRef && (
          // This element is NOT the exact design of Figma.
          // The reason is to make the information easier for our support team to see as it was previously too small.
          <PSmallGray
            style={{ textAlign: 'center' }}
            lineHeight={FontSizeREM.PX17}
          >
            Código: {frontendLogRef}
          </PSmallGray>
        )}
        <Div
          position="absolute"
          left="0px"
          top="100%"
          transform="translateY(-10%)"
          width="100%"
          margin="0 0 12px"
        >
          <Button
            style={{ width: '100%' }}
            type="button"
            onClick={buttonState.onClick}
          >
            {buttonState.label}
          </Button>
        </Div>
      </Div>
    </ErrorContainer>
  );
};

export default ErrorFallback;
