export type AssistancesState = {
  assistances: AssistancesObject;
} & AssistancesActions;

export interface AssistancesObject {
  code: string;
  contractedPlan?: string;
  planPrice: string;
  paymentMeans: string;
  productType: string;
  productId: string;
  containsCancellationFee: boolean;
  cancellationFee?: string;
  cancellation: AssistancesCancellationFlow;
}

export interface AssistancesActions {
  setAssistancesObject: (assistances: AssistancesObject) => void;
  setAssistancesCancellation: (
    assistancesCancellation: AssistancesCancellationFlow,
  ) => void;
  setAssistancesCancellationReasonCode: (
    cancellationReasonCode: number,
  ) => void;
  setAssistancesCancellationReasons: (
    cancellationReasons: AssistancesCancellationReason[],
  ) => void;
  setAssistancesCancellationTerms: (
    isTermsAcnConditionsAccepted: boolean,
  ) => void;
  setAssistancesCancellationReceipt: (
    receipt: AssistanceCancellationResponse,
  ) => void;
  resetAssistances: () => void;
}

export interface AssistancesCancellationFlow {
  isTermsAndConditionsAccepted: boolean;
  reasonCode: number;
  reasons: AssistancesCancellationReason[];
  receipt: AssistanceCancellationResponse;
}

export interface AssistancesCancellationReason {
  codigo: number;
  descricao: string;
}

export interface AssistanceCancellationResponse {
  dataCancelamento: string;
  nome: string;
  prestadoraServico: string;
  protocolo: string;
}

export const INITIAL_STATE: AssistancesObject = {
  code: '',
  contractedPlan: '',
  paymentMeans: '',
  planPrice: '',
  productType: '',
  productId: '',
  containsCancellationFee: false,
  cancellation: {
    isTermsAndConditionsAccepted: false,
    reasonCode: 0,
    reasons: [],
    receipt: {
      dataCancelamento: '',
      nome: '',
      prestadoraServico: '',
      protocolo: '',
    },
  },
};
