import BridgeService from 'common/services/bridgeService';
import { EnvVariableService } from 'common/services/envService';
import { EnvVariableKeys } from 'common/services/envService/types';
import { MockService } from 'common/services/mockService';
import { MonitoringService } from 'common/services/monitoringService';
import React, { useCallback, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import useBoundState from 'store';
import { Div } from '../../styles/commons';
import { _try } from '../../utils/errorUtils';
import { AppInfoService } from 'common/services/appInfoService';

const OnWebviewInit: React.FC = () => {
  const handleError = useErrorHandler();

  const setUserInfo = useBoundState((state) => state.setUserInfo);

  const setIsDarkMode = useBoundState((state) => state.setIsDarkMode);

  const isRemoteBridgeDebugLogEnable = EnvVariableService.getVariableAsBoolean(
    EnvVariableKeys.REMOTE_BRIDGE_DEBUG_LOG_ENABLED,
  );

  const getUserInfo = useCallback(
    () =>
      _try(
        async () => {
          const response = await BridgeService.getUserInfo();
          setUserInfo(response);
          await MonitoringService.setDefaultAttributes(response.account);
          if (isRemoteBridgeDebugLogEnable) {
            BridgeService.enableRemoteBridgeDebugLog();
          }
          if (!MockService.account) {
            MockService.account = response.account;
            MockService.prepareMatchHeadersByUserAccount();
          }
          setIsDarkMode(AppInfoService.isDarkMode);
        },
        handleError,
        'OnWebviewInit.getUserInfo',
      ),
    [handleError, setUserInfo, isRemoteBridgeDebugLogEnable, setIsDarkMode],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Div />;
};

export default OnWebviewInit;
