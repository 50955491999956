import React from 'react';

import { RoutesInfo } from 'routes/types';
import { Route } from 'wouter';

const GadgetCancellation = React.lazy(() => import('../pages/cancellation'));
const GadgetCancellationSurvey = React.lazy(
  () => import('../pages/cancellationSurvey'),
);
const GadgetCancellationRevision = React.lazy(
  () => import('../pages/cancellationRevision'),
);
const GadgetCancellationReceipt = React.lazy(
  () => import('../pages/cancellationReceipt'),
);

export const GadgetRoutesInfo: Record<string, RoutesInfo> = {
  cancelGadget: { path: '/gadget/cancellation' },
  surveyGadget: { path: '/gadget/cancellation/survey' },
  revisionGadget: { path: '/gadget/cancellation/revision' },
  receiptGadget: { path: '/gadget/cancellation/receipt' },
};

export const GadgetModule = () => (
  <>
    <Route
      path={GadgetRoutesInfo.cancelGadget.path}
      component={GadgetCancellation}
    />
    <Route
      path={GadgetRoutesInfo.surveyGadget.path}
      component={GadgetCancellationSurvey}
    />
    <Route
      path={GadgetRoutesInfo.revisionGadget.path}
      component={GadgetCancellationRevision}
    />
    <Route
      path={GadgetRoutesInfo.receiptGadget.path}
      component={GadgetCancellationReceipt}
    />
  </>
);
