export type InsuracesState = {
  insurances: InsurancesObject;
} & InsurancesActions;

export interface InsurancesObject {
  title: string;
  categories: InsurancesCategorie[];
}

export interface InsurancesActions {
  setInsurancesObject: (insurances: InsurancesObject) => void;
  resetInsurance: () => void;
}

export interface InsurancesCategorie {
  title: string;
  type: string;
  contracts: Insurance[];
}

export interface Insurance {
  title: string;
  icon: string;
  tag: InsuranceTag;
  description: string;
  action: InsuranceAction;
}
export interface InsuranceTag {
  shapeColor: string;
  textColor: string;
  title: string;
}

export interface InsuranceAction {
  deeplink: string;
  type: string;
}

export const INITIAL_STATE: InsurancesObject = {
  title: '',
  categories: [],
};
