import React, { FC } from 'react';
import * as S from './styles';
import { SeparatorSectionType } from './types';

const Separator: FC<SeparatorSectionType> = ({
  fullWidth = false,
  height,
  type,
  color,
  margin,
}) =>
  type === 'spaced' ? (
    <S.Spacer height={height} />
  ) : (
    <S.Separator
      fullWidth={fullWidth}
      height={height}
      type={type}
      color={color}
      margin={margin}
    />
  );

export default Separator;
