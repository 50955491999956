import { StateCreator } from 'zustand';
import { AssistancesState, INITIAL_STATE } from './types';

const createAssistancesSlice: StateCreator<
  AssistancesState,
  [],
  [],
  AssistancesState
> = (set) => ({
  assistances: INITIAL_STATE,
  setAssistancesObject: (assistances) => set(() => ({ assistances })),
  setAssistancesCancellation: (assistancesCancellation) =>
    set((state) => ({
      assistances: {
        ...state.assistances,
        cancellation: {
          ...state.assistances.cancellation,
          cancellation: assistancesCancellation,
        },
      },
    })),
  setAssistancesCancellationReasonCode: (cancellationReasonCode) =>
    set((state) => ({
      assistances: {
        ...state.assistances,
        cancellation: {
          ...state.assistances.cancellation,
          reasonCode: cancellationReasonCode,
        },
      },
    })),
  setAssistancesCancellationReasons: (cancellationReasons) =>
    set((state) => ({
      assistances: {
        ...state.assistances,
        cancellation: {
          ...state.assistances.cancellation,
          reasons: cancellationReasons,
        },
      },
    })),

  setAssistancesCancellationTerms: (isTermsAcnConditionsAccepted) =>
    set((state) => ({
      assistances: {
        ...state.assistances,
        cancellation: {
          ...state.assistances.cancellation,
          isTermsAndConditionsAccepted: isTermsAcnConditionsAccepted,
        },
      },
    })),
  setAssistancesCancellationReceipt: (receipt) =>
    set((state) => ({
      assistances: {
        ...state.assistances,
        cancellation: { ...state.assistances.cancellation, receipt: receipt },
      },
    })),
  resetAssistances: () => set(() => ({ assistances: INITIAL_STATE })),
});

export default createAssistancesSlice;
