import { StateCreator } from 'zustand';
import { INITIAL_STATE, InsuracesState } from './types';

const createInsurancesSlice: StateCreator<
  InsuracesState,
  [],
  [],
  InsuracesState
> = (set) => ({
  insurances: INITIAL_STATE,
  setInsurancesObject: (insurances) => set(() => ({ insurances })),
  resetInsurance: () => set(() => ({ insurances: INITIAL_STATE })),
});

export default createInsurancesSlice;
