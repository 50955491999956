import { StateCreator } from 'zustand';
import { AffinitiesState, INITIAL_STATE } from './types';

const createAffinitesSlice: StateCreator<
  AffinitiesState,
  [],
  [],
  AffinitiesState
> = (set) => ({
  affinities: INITIAL_STATE,
  setAffinitiesObject: (affinities) => set(() => ({ affinities })),
  setAffinitiesCancellation: (affinitiesCancellation) =>
    set((state) => ({
      affinities: {
        ...state.affinities,
        cancellation: {
          ...state.affinities.cancellation,
          cancellation: affinitiesCancellation,
        },
      },
    })),
  setAffinitiesCancellationReasonCode: (cancellationReasonCode) =>
    set((state) => ({
      affinities: {
        ...state.affinities,
        cancellation: {
          ...state.affinities.cancellation,
          reasonCode: cancellationReasonCode,
        },
      },
    })),
  setAffinitiesCancellationReasons: (cancellationReasons) =>
    set((state) => ({
      affinities: {
        ...state.affinities,
        cancellation: {
          ...state.affinities.cancellation,
          reasons: cancellationReasons,
        },
      },
    })),

  setAffinitiesCancellationTerms: (isTermsAcnConditionsAccepted) =>
    set((state) => ({
      affinities: {
        ...state.affinities,
        cancellation: {
          ...state.affinities.cancellation,
          isTermsAndConditionsAccepted: isTermsAcnConditionsAccepted,
        },
      },
    })),
  setAffinitiesCancellationReceipt: (receipt) =>
    set((state) => ({
      affinities: {
        ...state.affinities,
        cancellation: { ...state.affinities.cancellation, receipt: receipt },
      },
    })),
  resetAffinities: () => set(() => ({ affinities: INITIAL_STATE })),
});

export default createAffinitesSlice;
