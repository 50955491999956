import { styled } from '@linaria/react';
import { FlexProps, SeparatorProps } from './types';

export const Flex = styled.div<FlexProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'unset'};
  gap: ${(props) => props.gap || 'unset'};
  margin: ${(props) => props.margin || '0'};
  align-items: ${(props) => props.alignItems || 'unset'};
`;

export const FlexRow = styled(Flex)`
  flex-direction: row;
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

export const Separator = styled.div<SeparatorProps>`
  border-bottom: ${(props) => props.height || '1px'}
    ${(props) => props.type || 'solid'}
    ${(props) => props.color || 'var(--gray100)'};
  width: ${(props) => (props.fullWidth ? '100vw' : '100%')};
  margin: ${(props) => props.margin || '0'};
  margin-left: ${(props) => (props.fullWidth ? '-24px' : '0')};
`;
