import { StateCreator } from 'zustand';
import { INITIAL_STATE, PageTrasitionState } from './type';

const createPageTransitionSlice: StateCreator<
  PageTrasitionState,
  [],
  [],
  PageTrasitionState
> = (set) => ({
  pageTrasition: INITIAL_STATE,
  setPageTrasitionDirection: (direction) =>
    set(() => ({ pageTrasition: { direction } })),
});

export default createPageTransitionSlice;
