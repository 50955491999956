export function convertStringTodate(date: string): Date {
  return new Date(date);
}

export function convertDateToStringPtBR(date: Date): string {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function currentZeroedDate(): Date {
  const zeroedDate = new Date();
  zeroedDate.setHours(0, 0, 0, 0);
  return zeroedDate;
}
