import { CachedItem } from './types';
import { openDB, IDBPDatabase } from 'idb';

/**
 * Database versioning has been encapsulated in this class
 * as it is NOT possible to change the schema externally
 * as we have decided to only use 1 table for the entire application.
 * This way, we avoid having to remember to evolve the database version every time there is a new table.
 */
const DB_VERSION = 1;
export class CacheService {
  private static _appDBName: string;

  /**
   * This method is responsible to initialize the use of a indexed database,
   * by defining the database name and version.
   * @param appDBName The name of the database/store;
   */
  static initCacheService(appDBName: string) {
    CacheService._appDBName = appDBName;
  }

  /**
   * Open an IndexedDB connection in the database
   * and configure the stores;
   *
   * This function also create the database if not exists;
   *
   * @returns An open connection
   */
  private static get db(): Promise<IDBPDatabase<unknown>> {
    if (!CacheService._appDBName) {
      const cacheServiceNotDefinedMessage =
        'CacheServiceError: The CacheService was not initialized. Before any operation the initCacheService must be called.';
      throw new Error(cacheServiceNotDefinedMessage);
    }

    return openDB<unknown>(CacheService._appDBName, DB_VERSION, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(CacheService._appDBName)) {
          const store = db.createObjectStore(CacheService._appDBName);
          /**
           * If it is necessary to create a new index, INCREASE the constant DB_VERSION;
           */
          store.createIndex('expiresAt', 'expiresAt');
        }
      },
    });
  }

  /**
   * This method open a connection to insert or update some specific item in the database
   * @param key The identifier of the specific item to query it
   */
  static async setItem(key: string, value: CachedItem<unknown>): Promise<void> {
    const dbConnection = await CacheService.db;
    await dbConnection.put(CacheService._appDBName, value, key);
    dbConnection.close();
  }

  /**
   * This method open a connection to query some specific item
   * @param key The identifier of the specific item to query it
   * @returns A @interface CachedItem if some item is found or @undefined otherwise
   */
  static async getItem<T>(key: string): Promise<CachedItem<T> | undefined> {
    const dbConnection = await CacheService.db;

    const cachedItem = await dbConnection.get(CacheService._appDBName, key);

    if (!cachedItem) {
      return undefined;
    }

    const now = Date.now();
    const expiresAt = cachedItem.expiresAt;

    if (now > expiresAt) {
      await CacheService.removeItem(key);
      return undefined;
    }

    dbConnection.close();

    return cachedItem as CachedItem<T>;
  }

  /**
   * This method open a connection to remove some specific item from the database
   * @param key The identifier of the specific item to query it
   */
  static async removeItem(key: string) {
    const dbConnection = await CacheService.db;
    await dbConnection.delete(CacheService._appDBName, key);
    dbConnection.close();
  }

  /**
   * This method is responsible to do an active cleaning of the stored items expired;
   * For this method it is important that only one connection is established.
   */
  static async clearExpiredItems(): Promise<void> {
    const dbConnection = await CacheService.db;

    const range = IDBKeyRange.upperBound(Date.now());

    const transaction = dbConnection.transaction(
      CacheService._appDBName,
      'readwrite',
    );

    const store = transaction.store;
    let cursor = await store.index('expiresAt').openKeyCursor(range);

    while (cursor) {
      await store.delete(cursor.primaryKey);
      cursor = await cursor.continue();
    }

    await transaction.done;

    dbConnection.close();
  }
}
