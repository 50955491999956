import BridgeService from 'common/services/bridgeService';
import { EnvVariableService } from 'common/services/envService';
import { EnvVariableKeys } from 'common/services/envService/types';
import { useEffect, useState } from 'react';
import { MainRoutesInfo } from '..';

export function useDefaultRoute(): string {
  const [defaultRoute, setDefaultRoute] = useState('/');

  const IS_NOT_RUNNING_PROD = !EnvVariableService.getVariableAsBoolean(
    EnvVariableKeys.INTER_ENV,
    'production',
  );

  useEffect(() => {
    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      setDefaultRoute(MainRoutesInfo.insurances.path);
    } else {
      setDefaultRoute(MainRoutesInfo.afterSalePreLoading.path);
    }
  }, [IS_NOT_RUNNING_PROD]);

  return defaultRoute;
}
