import { StateCreator } from 'zustand';
import { GadgetState, INITIAL_STATE } from './types';

const createGadgetSlice: StateCreator<GadgetState, [], [], GadgetState> = (
  set,
) => ({
  gadget: INITIAL_STATE,
  setGadgetObject: (gadget) => set(() => ({ gadget })),
  setGadgetCancellationReasonCode: (cancellationReasonCode) =>
    set((state) => ({
      gadget: {
        ...state.gadget,
        cancellation: {
          ...state.gadget.cancellation,
          reasonCode: cancellationReasonCode,
        },
      },
    })),
  setGadgetCancellationReasons: (cancellationReasons) =>
    set((state) => ({
      gadget: {
        ...state.gadget,
        cancellation: {
          ...state.gadget.cancellation,
          reasons: cancellationReasons,
        },
      },
    })),
  setGadgetCancellationTerms: (isTermsAcnConditionsAccepted) =>
    set((state) => ({
      gadget: {
        ...state.gadget,
        cancellation: {
          ...state.gadget.cancellation,
          isTermsAndConditionsAccepted: isTermsAcnConditionsAccepted,
        },
      },
    })),
  setGadgetCancellationReceipt: (receipt) =>
    set((state) => ({
      gadget: {
        ...state.gadget,
        cancellation: { ...state.gadget.cancellation, receipt: receipt },
      },
    })),
  resetGadget: () => set(() => ({ gadget: INITIAL_STATE })),
});

export default createGadgetSlice;
