export type QueryParamsState = {
  queryParams: QueryParamsObject;
} & QueryParamsActions;

export interface QueryParamsObject {
  page?: string;
  productName?: string;
  productId?: string;
  /**
   * This param determine the @param MockService.shouldMock
   */
  mock?: boolean;
  /**
   * This param determine the @param MockService.account to be used in the requests
   */
  mockContaCorrente?: string;
  /**
   * This param determine the @param MockService.apiKey
   * to be used to communicate with the mock server
   */
  mockApiKey?: string;
  darkMode?: string;
}

export interface QueryParamsActions {
  setPage: (page?: string) => void;
  setProductId: (productId?: string) => void;
  setProductName: (productName?: string) => void;
  setMock: (mock?: boolean) => void;
  setMockContaCorrente: (mockContaCorrente?: string) => void;
  setMockApiKey: (mockApiKey?: string) => void;
  resetQueryParams: () => void;
}

export const INITIAL_STATE: QueryParamsObject = {
  mock: false,
  mockContaCorrente: '',
  mockApiKey: '',
};
