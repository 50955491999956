import { styled } from '@linaria/react';
/**
 * Overlay used to block the screen while loading.
 */
export const OverlayBlock = styled.div<{ show: boolean }>`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0%);
  display: ${(props) => (props.show ? 'block' : 'none')};
`;
