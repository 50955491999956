export interface Traveler {
  travelerName: string;
  voucherNumber: string;
  url: string;
  isHolder: boolean;
}

export type VoucherTravelState = {
  voucherTravel: VoucherTravelObject;
} & VoucherTravelActions;

export interface VoucherTravelObject {
  planType: string;
  travelers: Traveler[];
}

export interface VoucherTravelActions {
  setVoucherTravel: (travelers: Traveler[]) => void;
  setVoucherPlanType: (planType: string) => void;
  resetVoucherTravel: () => void;
}

export const INITIAL_STATE: VoucherTravelObject = {
  planType: '',
  travelers: [],
};
