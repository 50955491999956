import { Button } from '@interco/inter-ui/components/Button';
import { usePreLoading } from 'common/hooks';
import { _try } from 'common/utils/errorUtils';
import { FlexColumn, FlexRow, Separator } from 'modules/lifeticket/styles';
import React, { FC, Fragment, lazy, useCallback, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import useBoundState from 'store';
import * as S from './styles';
import BridgeService from 'common/services/bridgeService';
import AfterSaleSkeleton from 'common/components/skeleton/afterSale';
import { ContainerWithFooter } from 'common/components/containerWithFooter';
import {
  convertDateToStringPtBR,
  convertStringTodate,
} from 'common/utils/dateTimeUtils';
import LifeticketCancellationReceiptAnalytics from './analytics';
import LifeticketCancellationService from 'modules/lifeticket/services/cancellationService';

const Home = lazy(() => import('@interco/icons/orangeds/SM/home'));
const CheckCircle = lazy(
  () => import('@interco/icons/orangeds/MD/check-circle'),
);

const LifeticketCancellationReceipt: FC = () => {
  const setNavbarTitle = useBoundState((state) => state.setNavbarTitle);
  const resetNavbar = useBoundState((state) => state.resetNavbar);
  const setIsLoading = useBoundState((state) => state.setIsLoading);
  const setNavbarHideLeftIcon = useBoundState(
    (state) => state.setNavbarHideLeftIcon,
  );
  const setNavbarRightIcon = useBoundState((state) => state.setNavbarRightIcon);
  const lifeticketState = useBoundState((state) => state.lifeticket);

  const setLifeticketCancellationReceipt = useBoundState(
    (state) => state.setLifeticketCancellationReceipt,
  );
  const lifeticketCancellationReceipt = useBoundState(
    (state) => state.lifeticket.cancellation?.receipt,
  );

  const handleError = useErrorHandler();

  const productName = useBoundState(
    (state) => state.afterSaleConfig?.metadata.productName,
  ) as string;

  const goToInsurance = useCallback(() => {
    void _try(
      async () => {
        await BridgeService.openDeepLink('bancointer://seguros');
      },
      handleError,
      'LifeticketCancellationReceipt.BridgeService.backToNative',
    );
  }, [handleError]);

  const callRequestedLifeticketCancellation = useCallback(
    () =>
      _try(
        async () => {
          const response =
            await LifeticketCancellationService.requestCancellation(
              lifeticketState?.code,
            );

          setLifeticketCancellationReceipt(response);
          setIsLoading(false);
        },
        handleError,
        'LifeticketCancellationService.requestCancellation',
      ),
    [
      handleError,
      lifeticketState?.code,
      setIsLoading,
      setLifeticketCancellationReceipt,
    ],
  );

  const { isLoaded } = usePreLoading(
    callRequestedLifeticketCancellation,
    'LifeticketCancellationReceipt.usePreLoading.callRequestedLifeticketCancellation',
  );

  const footer = (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Separator fullWidth type="solid" height="8px" margin="0 0 32px" />
      <Button onClick={goToInsurance}>Voltar para Seguros</Button>
    </div>
  );

  useEffect(() => {
    resetNavbar();
    if (isLoaded) {
      setNavbarTitle(productName);
      setNavbarHideLeftIcon(true);
      setNavbarRightIcon(
        <div
          style={{ width: '100%', height: '20px' }}
          id="navbar-right"
          onClick={goToInsurance}
        >
          <Home color="var(--primary500)" width="20" height="20" />
        </div>,
      );
      LifeticketCancellationReceiptAnalytics.receiptResult(
        productName,
        'sucesso',
        'Seu seguro foi cancelado com sucesso!',
        lifeticketCancellationReceipt?.price,
        productName,
      );
    }
  }, [
    lifeticketCancellationReceipt?.price,
    goToInsurance,
    isLoaded,
    productName,
    resetNavbar,
    setNavbarHideLeftIcon,
    setNavbarRightIcon,
    setNavbarTitle,
    lifeticketCancellationReceipt,
  ]);

  return (
    <Fragment>
      {isLoaded ? (
        <ContainerWithFooter stickyFooter={footer}>
          <FlexColumn margin="24px 0 24px" style={{ alignItems: 'center' }}>
            <S.Circle color="var(--success500)">
              <CheckCircle width="32px" height="32px" color="var(--white100)" />
            </S.Circle>
          </FlexColumn>
          <S.Title>Seu seguro foi cancelado com sucesso!</S.Title>
          <Separator
            type="dashed"
            margin="24px 0 24px"
            color="var(--gray200)"
          />
          <S.SubTitle>Dados do cancelamento</S.SubTitle>
          <FlexRow justifyContent="space-between">
            <S.Label>Preço por mês</S.Label>
            <S.Value>{lifeticketCancellationReceipt?.price}</S.Value>
          </FlexRow>
          <FlexRow justifyContent="space-between">
            <S.Label>Data de solicitação</S.Label>
            <S.Value>
              {convertDateToStringPtBR(
                convertStringTodate(lifeticketCancellationReceipt?.createdAt),
              )}
            </S.Value>
          </FlexRow>
          <FlexRow justifyContent="space-between">
            <S.Label>Data do cancelamento</S.Label>
            <S.Value>
              {convertDateToStringPtBR(
                convertStringTodate(lifeticketCancellationReceipt?.canceledAt),
              )}
            </S.Value>
          </FlexRow>
        </ContainerWithFooter>
      ) : (
        <AfterSaleSkeleton />
      )}
    </Fragment>
  );
};

export default LifeticketCancellationReceipt;
