import React from 'react';
import { RoutesInfo } from 'routes/types';
import { Route } from 'wouter';

const DentalCancellation = React.lazy(() => import('../pages/cancellation'));
const DentalCancellationSurvey = React.lazy(
  () => import('../pages/cancellationSurvey'),
);
const DentalCancellationRevision = React.lazy(
  () => import('../pages/cancellationRevision'),
);
const DentalCancellationReceipt = React.lazy(
  () => import('../pages/cancellationReceipt'),
);

export const DentalRoutesInfo: Record<string, RoutesInfo> = {
  cancelDental: { path: '/dental/cancellation' },
  surveyDental: { path: '/dental/cancellation/survey' },
  revisionDental: { path: '/dental/cancellation/revision' },
  receiptDental: { path: '/dental/cancellation/receipt' },
};

export const DentalModule = () => (
  <>
    <Route
      path={DentalRoutesInfo.cancelDental.path}
      component={DentalCancellation}
    />
    <Route
      path={DentalRoutesInfo.surveyDental.path}
      component={DentalCancellationSurvey}
    />
    <Route
      path={DentalRoutesInfo.revisionDental.path}
      component={DentalCancellationRevision}
    />
    <Route
      path={DentalRoutesInfo.receiptDental.path}
      component={DentalCancellationReceipt}
    />
  </>
);
