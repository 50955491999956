import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  height: calc(100vh - 56px);
  position: relative;
  background-color: var(--neutral-theme);
`;

export const Content = styled.div`
  &::-webkit-scrollbar {
    height: 0 !important;
  }
`;

export const StickyFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--neutral-theme);
  padding: 0 24px 16px;
`;
