import { IWbUserInfo } from '@interco/inter-webview-bridge';

export interface IUserBalanceInfo {
  blockedByCheck?: number;
  judiciallyBlocked?: number;
  avaliable?: number;
}

export type UserInfoState = {
  user: UserObject;
} & UserActions;

export interface UserObject {
  info: IWbUserInfo;
  balanceInfo?: IUserBalanceInfo;
}

export interface UserActions {
  setUserInfo: (info: IWbUserInfo) => void;
  setBalanceInfo: (balanceInfo?: IUserBalanceInfo) => void;
  resetUser: () => void;
}

export const INITIAL_STATE: UserObject = {
  info: {
    name: '',
    email: '',
    account: '',
    cpf: '',
    cellPhoneNumber: '',
    phoneNumber: '',
    profile: '',
    birthDate: 0,
    userType: 'basic_account',
    segmentation: 'DIGITAL'
  },
};
