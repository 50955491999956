import React from 'react';
import { RoutesInfo } from 'routes/types';
import { Route } from 'wouter';

const Voucher = React.lazy(() => import('../pages/voucher'));

export const TravelRoutesInfo: Record<string, RoutesInfo> = {
  voucher: { path: '/travel/voucher' },
};

export const TravelModule = () => (
  <>
    <Route path={TravelRoutesInfo.voucher.path} component={Voucher} />
  </>
);
