export enum EnvVariableKeys {
  INTER_ENV = 'REACT_APP_INTER_ENV',
  USE_REDIRECT_PREFIX = 'REACT_APP_USE_REDIRECT_PREFIX',
  APP_DB_NAME = 'REACT_APP_DB_NAME',
  API_BASE_URL = 'REACT_APP_API_BASE_URL',
  MOCK_SERVER_BASE_URL = 'REACT_APP_MOCK_SERVER_BASE_URL',
  LOCAL_LOG_ENABLED = 'REACT_APP_LOCAL_LOG_ENABLED',
  REMOTE_LOG_ENABLED = 'REACT_APP_REMOTE_LOG_ENABLED',
  REMOTE_BRIDGE_DEBUG_LOG_ENABLED = 'REACT_APP_REMOTE_BRIDGE_DEBUG_LOG_ENABLED',
  API_USER_INFO_V3 = 'REACT_APP_API_USER_INFO_V3',
  API_BRIDGE_BALANCE_V3 = 'REACT_APP_API_BRIDGE_BALANCE_V3',
  APP_API_AFFINITIES_CANCELLATION_PATH = 'REACT_APP_API_AFFINITIES_CANCELLATION_PATH',
  APP_API_AFFINITIES_BRIDGE_CANCELLATION_PATH = 'REACT_APP_API_AFFINITIES_BRIDGE_CANCELLATION_PATH',
  APP_API_AFFINITIES_CANCELLATION_REASONS_PATH = 'REACT_APP_API_AFFINITIES_CANCELLATION_REASONS_PATH',
  APP_API_AFFINITIES_BRIDGE_CANCELLATION_REASONS_PATH = 'REACT_APP_API_AFFINITIES_BRIDGE_CANCELLATION_REASONS_PATH',
  APP_API_ASSISTANCES_CANCELLATION_PATH = 'REACT_APP_API_ASSISTANCES_CANCELLATION_PATH',
  APP_API_ASSISTANCES_BRIDGE_CANCELLATION_PATH = 'REACT_APP_API_ASSISTANCES_BRIDGE_CANCELLATION_PATH',
  APP_API_ASSISTANCES_CANCELLATION_REASONS_PATH = 'REACT_APP_API_ASSISTANCES_CANCELLATION_REASONS_PATH',
  APP_API_ASSISTANCES_BRIDGE_CANCELLATION_REASONS_PATH = 'REACT_APP_API_ASSISTANCES_BRIDGE_CANCELLATION_REASONS_PATH',
  APP_API_INSURANCES_PATH = 'REACT_APP_API_INSURANCES_PATH',
  APP_API_GADGET_CANCELLATION_REASONS_PATH = 'REACT_APP_API_GADGET_CANCELLATION_REASONS_PATH',
  APP_API_GADGET_BRIDGE_CANCELLATION_REASONS_PATH = 'REACT_APP_API_GADGET_BRIDGE_CANCELLATION_REASONS_PATH',
  APP_API_GADGET_CANCELLATION_PATH = 'REACT_APP_API_GADGET_CANCELLATION_PATH',
  APP_API_GADGET_BRIDGE_CANCELLATION_PATH = 'REACT_APP_API_GADGET_BRIDGE_CANCELLATION_PATH',
  APP_API_DENTAL_CANCELLATION_REASONS_PATH = 'REACT_APP_API_DENTAL_CANCELLATION_REASONS_PATH',
  APP_API_DENTAL_BRIDGE_CANCELLATION_REASONS_PATH = 'REACT_APP_API_DENTAL_BRIDGE_CANCELLATION_REASONS_PATH',
  APP_API_DENTAL_CANCELLATION_PATH = 'REACT_APP_API_DENTAL_CANCELLATION_PATH',
  APP_API_DENTAL_BRIDGE_CANCELLATION_PATH = 'REACT_APP_API_DENTAL_BRIDGE_CANCELLATION_PATH',
  APP_API_DENTAL_CANCELLATION_TERMS_PATH = 'REACT_APP_API_DENTAL_CANCELLATION_TERMS_PATH',
  APP_API_DENTAL_BRIDGE_CANCELLATION_TERMS_PATH = 'REACT_APP_API_DENTAL_BRIDGE_CANCELLATION_TERMS_PATH',
  APP_API_DENTAL_CANCELLATION_MANUAL_PATH = 'REACT_APP_API_DENTAL_CANCELLATION_MANUAL_PATH',
  APP_API_DENTAL_BRIDGE_CANCELLATION_MANUAL_PATH = 'REACT_APP_API_DENTAL_BRIDGE_CANCELLATION_MANUAL_PATH',
  APP_API_REQUESTED_LIFETICKET_CANCELLATION_PATH = 'REACT_APP_API_REQUESTED_LIFETICKET_CANCELLATION_PATH',
  APP_API_REQUESTED_LIFETICKET_BRIDGE_CANCELLATION_PATH = 'REACT_APP_API_REQUESTED_LIFETICKET_BRIDGE_CANCELLATION_PATH',

  APP_MKTPL_CANCELLATION_URL = 'REACT_APP_MKTPL_CANCELLATION_URL',
}
