import { MockService } from 'common/services/mockService';
import { WouterService } from 'common/services/wouterService';
import { handleParamsPage } from 'common/utils/routesUtils';
import { useMemo } from 'react';
import { QueryParamsObject } from 'store/queryParams/types';
import useBoundState from '../../../store';
import { useQuery } from '../useQuery';

interface IUseQueryParamsType {
  handleQueryParams: () => void;
}

/**
 * useQueryParams
 *
 * A Hook used to handle the deep link query params.
 * @returns handleQueryParams, a function to process every param passed
 *
 * @usage
 * const { handleQueryParams } = useQueryParams();
 *
 * First of all, we use the query that comes from another hook, that return
 * a URLSearchParams;
 * @func useQuery
 *
 * After that we split this query, by the ";" , into an array of strings:
 * @func getQueryParams
 * @returns: [0: "page:SomePage", 1: "someparam:SomeRandomParam"]
 *
 * Next, we pick up this array with the params and we turn it into a object;
 * @func queryParamsAsObj
 * @returns: {page: "SomePage", someparam: "SomeRandomParam"}
 *
 * In case of mock inside the query params, as the API_KEY:
 * @func handleMock
 *
 * After the all treat above the query params, we redirect to the the respective page
 * @func handleRedirect
 *
 */

export function useQueryParams(): IUseQueryParamsType {
  const setPage = useBoundState((state) => state.setPage);
  const setProductId = useBoundState((state) => state.setProductId);
  const setProductName = useBoundState((state) => state.setProductName);
  const setMock = useBoundState((state) => state.setMock);
  const setMockContaCorrente = useBoundState(
    (state) => state.setMockContaCorrente,
  );
  const setMockApiKey = useBoundState((state) => state.setMockApiKey);
  const query = useQuery();

  const setIsDarkMode = useBoundState((state) => state.setIsDarkMode);

  const queryParamsArray = useMemo(() => {
    const queryParams: { [key: string]: string } = {};
    query.forEach((value: string, key: string | number) => {
      queryParams[key] = value;
    });
    return queryParams;
  }, [query]);

  const splitedQuery = useMemo(() => {
    if (
      queryParamsArray !== undefined &&
      queryParamsArray.params !== undefined
    ) {
      return queryParamsArray.params.split(';');
    }

    return [];
  }, [queryParamsArray]);

  const queryParamsAsObj = useMemo(() => {
    const queryParams: { [key: string]: string } = {};
    splitedQuery?.forEach((item: string) => {
      const dict = item.split(':');
      const key = dict[0];
      const value = dict[1];
      queryParams[key] = value;
    });
    return queryParams as QueryParamsObject;
  }, [splitedQuery]);

  const handleMock = () => {
    const shouldMock = String(queryParamsAsObj.mock).toLowerCase() === 'true';
    MockService.prepareMock({
      shouldMock,
      account: queryParamsAsObj.mockContaCorrente,
      apiKey: queryParamsAsObj.mockApiKey,
    });
  };

  const handleRedirect = () => {
    if (queryParamsAsObj.page) {
      WouterService.navigate(handleParamsPage(queryParamsAsObj.page));
    }
  };

  const updateQueryParamsState = () => {
    setPage(queryParamsAsObj.page);
    setProductId(queryParamsAsObj.productId);
    setProductName(queryParamsAsObj.productName);
    setMock(queryParamsAsObj.mock);
    setMockApiKey(queryParamsAsObj.mockApiKey);
    setMockContaCorrente(queryParamsAsObj.mockContaCorrente);
    setIsDarkMode(Boolean(queryParamsAsObj.darkMode));
  };

  const handleQueryParams = (): void => {
    if (
      queryParamsArray !== undefined &&
      queryParamsArray.params !== undefined
    ) {
      handleMock();
      updateQueryParamsState();
      handleRedirect();
    }
  };

  return { handleQueryParams };
}
