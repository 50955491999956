import React, { FC, useEffect, useState } from 'react';
import useBoundState from 'store';
import { PageDirections } from 'store/pageTransitionAnimation/type';
import { useLocation } from 'wouter';
import './transition-style.css';

const TrasitionManager: FC = ({ children }) => {
  const pageSliderOff = 'pageSlider-off';
  const pageSliderOn = 'pageSlider-on';
  const pageSliderLeft = 'pageSlider-left';
  const pageSliderRight = 'pageSlider-right';
  const pageSliderEnd = 'pageSlider-end';
  const delay = 200;

  // We need the location to verify if is to change animation
  const location: string = useLocation()[0];
  const { direction } = useBoundState((state) => state.pageTrasition);
  const [className, setClassName] = useState(pageSliderEnd);
  const [curLocation, setCurLocation] = useState(location);

  useEffect(() => {
    if (curLocation !== location) {
      setClassName(
        direction === PageDirections.BACKWARD
          ? `${pageSliderOff} ${pageSliderRight}`
          : `${pageSliderOff} ${pageSliderLeft}`,
      );
      setCurLocation(location);
    }
  }, [location, direction, curLocation]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (className === `${pageSliderOff} ${pageSliderLeft}`) {
      timeout = setTimeout(() => {
        setClassName(`${pageSliderOn} ${pageSliderEnd}`);
      }, delay);
    }

    if (className === `${pageSliderOff} ${pageSliderRight}`) {
      timeout = setTimeout(() => {
        setClassName(`${pageSliderOn} ${pageSliderEnd}`);
      }, delay);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [className]);

  return <div className={`pageRoot ${className}`}>{children}</div>;
};

export default TrasitionManager;
