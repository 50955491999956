export type LifeticketState = {
  lifeticket: LifeticketObject;
} & LifeticketActions;

export interface LifeticketObject {
  code: string;
  contractedPlan?: string;
  planPrice: string;
  paymentMeans: string;
  productType: string;
  productId: string;
  containsCancellationFee: boolean;
  cancellationFee?: string;
  status?: string;
  cancellation: LifeticketCancellationFlow;
}

export interface LifeticketActions {
  setLifeticketObject: (lifeticket: LifeticketObject) => void;
  setLifeticktCancellation: (
    lifeticketCancellation: LifeticketCancellationFlow,
  ) => void;
  setLifeticketCancellationReceipt: (
    receipt: LifeticketCancellationResponse,
  ) => void;
  resetLifeticket: () => void;
}

export interface LifeticketCancellationFlow {
  isTermsAndConditionsAccepted: boolean;
  receipt: LifeticketCancellationResponse;
}

export interface LifeticketCancellationResponse {
  price: string;
  createdAt: string;
  canceledAt: string;
}

export const INITIAL_STATE: LifeticketObject = {
  code: '',
  contractedPlan: '',
  paymentMeans: '',
  planPrice: '',
  productType: '',
  productId: '',
  containsCancellationFee: false,
  cancellation: {
    isTermsAndConditionsAccepted: false,
    receipt: {
      price: '',
      createdAt: '',
      canceledAt: '',
    },
  },
};
