import { useQueryParams } from 'common/hooks/useQueryParams';
import React, { useEffect } from 'react';
import { Switch } from 'wouter';
import AfterSaleSkeleton from 'common/components/skeleton/afterSale';
import ModulesRoutes from 'modules';
import TransitionManager from '../common/components/transitionManager';

const NavBarSection = React.lazy(() => import('common/components/navBar'));
const BottomSheetSection = React.lazy(
  () => import('common/components/bottomSheet'),
);
const RoutesComponent = () => {
  const { handleQueryParams } = useQueryParams();

  useEffect(() => {
    handleQueryParams();
  }, [handleQueryParams]);

  return (
    <React.Suspense fallback={<AfterSaleSkeleton />}>
      <TransitionManager>
        <NavBarSection />
        <Switch>
          <ModulesRoutes />
        </Switch>
      </TransitionManager>
      <BottomSheetSection />
    </React.Suspense>
  );
};

export default RoutesComponent;
