import { MainModule } from './main/routes';
import { AffinitiesModule } from './affinities/routes';
import { TravelModule } from './travel/routes';
import { AssistancesModule } from './assistances/routes';
import { GadgetModule } from './gadget/routes';
import { DentalModule } from './dental/routes';
import { LifeticketModule } from './lifeticket/routes';

const ModulesRoutes = () => (
  <>
    <MainModule />
    <AffinitiesModule />
    <AssistancesModule />
    <LifeticketModule />
    <TravelModule />
    <GadgetModule />
    <DentalModule />
  </>
);

export default ModulesRoutes;
