import { MainRoutesInfo } from 'modules/main/routes';

export const RoutesByQueryParams: {
  [params: string]: string;
} = { AfterSalePreLoading: MainRoutesInfo.afterSalePreLoading.path };

export function handleParamsPage(params: string): string {
  return RoutesByQueryParams[params];
}

export function buildParams(splitedQuery: string[]): string {
  let params = '';

  splitedQuery.forEach((item: string) => {
    const dict = item.split(':');

    const key = dict[0];
    const value = dict[1];

    if (key !== 'prefix' && key && value) {
      params = params.concat(key, ':', value, ';');
    }
  });

  return params ? `?params=${params}` : '';
}
