import { AfterSaleState } from './types';
import { StateCreator } from 'zustand';

const createAfterSaleSlice: StateCreator<
  AfterSaleState,
  [],
  [],
  AfterSaleState
> = (set) => ({
  afterSaleConfig: undefined,
  setAfterSaleConfig: (config) => set(() => ({ afterSaleConfig: config })),
  resetAfterSaleConfig: () => set(() => ({ afterSaleConfig: undefined })),
});

export default createAfterSaleSlice;
