import { styled } from '@linaria/react';
import { SeparatorSectionType } from './types';

export const Separator = styled.div<SeparatorSectionType>`
  width: ${(props) => (props.fullWidth ? '100vw' : '100%')};
  border-bottom: ${(props) => props.height || '1px'}
    ${(props) => props.type || 'solid'}
    ${(props) => props.color || 'var(--gray100)'};
  margin: ${(props) => props.margin || '32px 0'};
  margin-left: ${(props) => (props.fullWidth ? '-24px' : '0')};
`;

export const Spacer = styled.div<SeparatorSectionType>`
  height: ${(props) => props.height || '32px'};
`;
