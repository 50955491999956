import { EnvVariableService } from 'common/services/envService';
import { ServiceError } from 'common/exceptions';
import { withErrorCodeRef } from 'common/utils/errorUtils';
import { unstable_batchedUpdates } from 'react-dom';
import useBoundState from 'store';
import { ErrorResponse } from 'store/error/types';
import BridgeService from '../bridgeService';
import { EnvVariableKeys } from '../envService/types';

/**
 *  This is the only enviroment which should not be selected from the EnvVariableService,
 * because this variable is always undefined in the production/staging enviroment
 * */
const { REACT_APP_MOCK_SERVER_API_KEY } = process.env;

const MOCK_SERVER_BASE_URL = EnvVariableService.getVariable(
  EnvVariableKeys.MOCK_SERVER_BASE_URL,
);

const IS_NOT_RUNNING_PROD = !EnvVariableService.getVariableAsBoolean(
  EnvVariableKeys.INTER_ENV,
  'production',
);

const STATUS_CODE_ACCEPTED = [401];
export class MockService {
  private static _baseUrl = MOCK_SERVER_BASE_URL;

  /**
   * The private key to set in the header 'x-api-key'
   * see @method FetchApiService.prepareApi and @method prepareMock
   */
  private static _apiKey = REACT_APP_MOCK_SERVER_API_KEY || '';

  /**
   * Represent the account of the client
   * and is used to match the correct return of mocked service
   * using the 'x-mock-match-request-headers' and 'x-mock-conta-corrente'
   * see @method prepareMock
   */
  private static _account: string;

  /**
   * The flag which determine if the app should consider the mock services
   * see @method prepareMock
   */
  private static _shouldMock: boolean;

  /**
   * The headers that determine the correct mock to return via postman mock server
   * using the 'x-mock-match-request-headers' and 'x-mock-conta-corrente'
   * see @method prepareMatchHeadersByUserAccount
   */
  private static _matchHeadersByUserAccount: Record<string, string>;

  static get baseUrl(): string {
    return MockService._baseUrl;
  }

  static get apiKey(): string {
    return MockService._apiKey;
  }

  static get account(): string {
    return MockService._account;
  }

  static set account(account: string) {
    MockService._account = account;
  }

  static get shouldMock(): boolean {
    return MockService._shouldMock;
  }

  static get matchHeadersByUserAccount(): Record<string, string> {
    return MockService._matchHeadersByUserAccount;
  }

  static prepareMatchHeadersByUserAccount(): void {
    if (
      MockService.shouldMock &&
      IS_NOT_RUNNING_PROD &&
      BridgeService.isBrowser()
    ) {
      MockService._matchHeadersByUserAccount = {
        'x-mock-conta-corrente': MockService.account || '',
        'x-mock-match-request-headers': 'x-mock-conta-corrente',
      };
    }
  }

  /**
   * The method witch configure the mock service to communicate with the mock server.
   * It should be call when was neccessary to setting the application mock strategy,
   * this is the only method that turn shouldMock to true and only have effect if:
   * - @var props.shouldMock is true
   * - @const IS_NOT_RUNNING_PROD is true
   */
  static prepareMock(props: {
    shouldMock: boolean;
    apiKey?: string;
    account?: string;
  }): void {
    if (props.shouldMock && IS_NOT_RUNNING_PROD && BridgeService.isBrowser()) {
      MockService._shouldMock = props.shouldMock;
    }

    /**
     * Even when should not mock (props.mock is not true)
     * the apiKey can be setted to mock the client
     * in staging environment and only for browser
     */
    if (props.apiKey) {
      MockService._apiKey = props.apiKey;
    }

    /**
     * Even when should not mock (props.mock is not true)
     * the account can be setted to differ the multiple scenarios
     * in development environment and only for browser
     */
    if (props.account) {
      MockService._account = props.account;
    }

    MockService.prepareMatchHeadersByUserAccount();
  }

  static handleMockServiceErrors(
    errorObject: unknown,
    errorCodeRef: string,
  ): Error {
    const errorHandled = withErrorCodeRef(
      new Error(JSON.stringify(errorObject)),
      errorCodeRef,
    );

    if (
      errorObject instanceof ServiceError &&
      STATUS_CODE_ACCEPTED.includes(errorObject.status)
    ) {
      const errorResponse: ErrorResponse = {
        totalErrors: 1,
        errors: [
          {
            code: 'api_key_unauthorized',
            message:
              'Não foi possível autenticar. Por favor verifique a mockApiKey provisionada e tente novamente.',
          },
        ],
      };

      unstable_batchedUpdates(() => {
        useBoundState.getState().resetError();
      });

      return new ServiceError<ErrorResponse>(
        true,
        errorResponse,
        errorResponse.errors[0].message,
        errorObject.status,
      );
    }

    return errorHandled;
  }
}
