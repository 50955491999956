import { MonitoringService } from 'common/services/monitoringService';
import React, { useEffect, useState } from 'react';
import { Flex } from '../../styles/commons';

// File with commented parts

interface IUsePreLoading {
  isLoaded: boolean;
  preLoading: JSX.Element;
}

/**
 * A hook that receive a callBack that need be async awaits or callbackhells
 * and an errorCodeRef to noticeError on MonitoringService
 * and returns IUsePreLoading when callBack was resolved
 *
 * @tutorial
 * const { isLoaded, preLoading } = usePreLoading(
 *  async () => {
 *    await Service.someRequest();
 *  }, 'error.code.ref.sample')
 *
 *  render(
 *   {isLoaded? (<SomeComponent/>) : (preLoading)}
 * )
 *
 * @param callBack A callBack to do some processing.
 * @param errorCodeRef An error code ref from the caller.
 * @returns IUsePreLoading isLoaded returns true when callBacks resolve,
 *  preLoading returns a component to show when callBacks is processing.
 */
export function usePreLoading(
  callBack: () => Promise<unknown>,
  errorCodeRef: string,
): IUsePreLoading {
  const [isLoaded, setIsLoaded] = useState(false);
  const preLoading = (
    <Flex height="100%" justifyContent="center" alignItems="center">
      Loading...
    </Flex>
  );

  useEffect(() => {
    let isSubscribed = true;
    callBack()
      .then(() => {
        if (isSubscribed) {
          setIsLoaded(true);
        }
      })
      .catch((err: Error) => {
        if (isSubscribed) {
          setIsLoaded(true);
        }
        MonitoringService.noticeError(err, { errorCodeRef });
      });
    return () => {
      isSubscribed = false;
    };
  }, [callBack, errorCodeRef]);

  return { isLoaded, preLoading };
}
