export type GadgetState = {
  gadget: GadgetObject;
} & GadgetActions;

export interface GadgetObject {
  code: string;
  contractedPlan?: string;
  planPrice: string;
  paymentMeans: string;
  productType: string;
  productId: string;
  containsCancellationFee: boolean;
  cancellationFee?: string;
  cancellation: GadgetCancellationFlow;
  manualUrl: string;
  termsAndConditionsUrl: string;
}

export interface GadgetActions {
  setGadgetObject: (gadget: GadgetObject) => void;
  setGadgetCancellationReasonCode: (cancellationReasonCode: number) => void;
  setGadgetCancellationReasons: (
    cancellationReasons: GadgetCancellationReason[],
  ) => void;
  setGadgetCancellationTerms: (isTermsAcnConditionsAccepted: boolean) => void;
  setGadgetCancellationReceipt: (receipt: GadgetCancellationResponse) => void;
  resetGadget: () => void;
}

export interface GadgetCancellationFlow {
  isTermsAndConditionsAccepted: boolean;
  reasonCode: number;
  reasons: GadgetCancellationReason[];
  receipt: GadgetCancellationResponse;
}
export interface GadgetCancellationReason {
  id: number;
  text: string;
}
export interface GadgetCancellationResponse {
  title: string;
  description: string;
}

export const INITIAL_STATE: GadgetObject = {
  code: '',
  contractedPlan: '',
  paymentMeans: '',
  planPrice: '',
  productType: '',
  productId: '',
  manualUrl: '',
  termsAndConditionsUrl: '',
  containsCancellationFee: false,
  cancellation: {
    isTermsAndConditionsAccepted: false,
    reasonCode: 0,
    reasons: [],
    receipt: {
      title: '',
      description: '',
    },
  },
};
