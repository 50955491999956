import BridgeService from 'common/services/bridgeService';
import { _try } from 'common/utils/errorUtils';
import { useCallback, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import useBoundState from 'store';
import Error from '../../../assets/error/Error.png';
import Warning from '../../../assets/warning/Warning.png';
import {
  IArrowLeftState,
  IButtonState,
  IImgState,
  IUseErrorFallbackConfig,
} from './types';
import { ErrorCodes } from 'store/error/types';

export function useErrorFallbackConfig(): IUseErrorFallbackConfig {
  const {
    errorResponse: { errors },
    isDetailedError,
  } = useBoundState((state) => state.error);
  const handlerError = useErrorHandler();

  const backToNative = useCallback(() => {
    void _try(
      async () => {
        await BridgeService.backToNative();
      },
      handlerError,
      'ErrorFallback.BackToNative',
    );
  }, [handlerError]);

  const isConfigNotFoundOrParamsAbsent: boolean =
    errors[0].code === ErrorCodes.CONFIG_NOT_FOUND ||
    errors[0].code === ErrorCodes.PARAMETER_ABSENT;

  const imgStateHandler: IImgState = useMemo(() => {
    if (isConfigNotFoundOrParamsAbsent) {
      return { src: Error, alt: 'Símbolo de erro' };
    }
    return {
      src: isDetailedError ? Warning : Error,
      alt: isDetailedError ? 'Símbolo de atenção' : 'Símbolo de erro',
    };
  }, [isConfigNotFoundOrParamsAbsent, isDetailedError]);

  const buttonStateHandler: IButtonState = {
    label: 'Voltar para seguros',
    onClick: () => backToNative(),
  };

  const arrowLeftStateHandler: IArrowLeftState = {
    onClick: () => backToNative(),
  };

  return {
    arrowLeftState: arrowLeftStateHandler,
    imgState: imgStateHandler,
    buttonState: buttonStateHandler,
  };
}
