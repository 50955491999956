import { StateCreator } from 'zustand';
import { ErrorState, INITIAL_STATE } from './types';

const createErrorSlice: StateCreator<ErrorState, [], [], ErrorState> = (
  set,
) => ({
  error: INITIAL_STATE,
  setHasError: (hasError: boolean) =>
    set((state) => ({ error: { ...state.error, hasError } })),
  setStatus: (status) =>
    set((state) => ({ error: { ...state.error, status } })),
  setErrorResponse: (errorResponse) =>
    set((state) => ({ error: { ...state.error, errorResponse } })),
  setErrorInstance: (errorInstance) =>
    set((state) => ({ error: { ...state.error, errorInstance } })),
  setIsDetailedError: (isDetailedError) =>
    set((state) => ({ error: { ...state.error, isDetailedError } })),
  resetError: () => set(() => ({ error: INITIAL_STATE })),
});

export default createErrorSlice;
