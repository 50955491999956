import React, { useEffect, useRef, useState } from 'react';

import * as S from './styles';
import { IContainerProps } from './types';

type ContainerType = React.FC<
  IContainerProps & React.HTMLAttributes<HTMLDivElement>
>;
export const ContainerWithFooter: ContainerType = ({
  children,
  stickyFooter,
  ...props
}) => {
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef<HTMLDivElement>(null);
  const NAVBAR_HEIGHT = 56;

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef?.current?.clientHeight);
    }
  }, []);

  return (
    <S.Container data-testid="container" {...props}>
      <S.Content
        style={{
          height: `calc(100vh - ${NAVBAR_HEIGHT + footerHeight}px)`,
        }}
        data-testid="container-content"
      >
        {children}
      </S.Content>

      {stickyFooter && (
        <S.StickyFooter ref={footerRef} data-testid="container-sticky-footer">
          {stickyFooter}
        </S.StickyFooter>
      )}
    </S.Container>
  );
};
