import React from 'react';
import { RoutesInfo } from 'routes/types';
import { Route } from 'wouter';

const AffinitiesCancellation = React.lazy(
  () => import('../pages/cancellation'),
);
const AffinitiesCancellationReceipt = React.lazy(
  () => import('../pages/cancellationReceipt'),
);
const AffinitiesCancellationRevision = React.lazy(
  () => import('../pages/cancellationRevision'),
);
const AffinitiesCancellationSurvey = React.lazy(
  () => import('../pages/cancellationSurvey'),
);

export const AffinitiesRoutesInfo: Record<string, RoutesInfo> = {
  cancelAffinity: { path: '/affinity/cancellation' },
  surveyAffinity: { path: '/affinity/cancellation/survey' },
  revisionAffinity: { path: '/affinity/cancellation/revision' },
  receiptAffinity: { path: '/affinity/cancellation/receipt' },
};

export const AffinitiesModule = () => (
  <>
    <Route
      path={AffinitiesRoutesInfo.cancelAffinity.path}
      component={AffinitiesCancellation}
    />
    <Route
      path={AffinitiesRoutesInfo.surveyAffinity.path}
      component={AffinitiesCancellationSurvey}
    />
    <Route
      path={AffinitiesRoutesInfo.revisionAffinity.path}
      component={AffinitiesCancellationRevision}
    />
    <Route
      path={AffinitiesRoutesInfo.receiptAffinity.path}
      component={AffinitiesCancellationReceipt}
    />
  </>
);
