import { StateCreator } from 'zustand';
import { DentalState, INITIAL_STATE } from './types';

const createDentalSlice: StateCreator<DentalState, [], [], DentalState> = (
  set,
) => ({
  dental: INITIAL_STATE,
  setDentalObject: (dental) => set(() => ({ dental })),

  setDentalCancellation: (dentalCancellation) =>
    set((state) => ({
      dental: {
        ...state.dental,
        cancellation: {
          ...state.dental.cancellation,
          cancellation: dentalCancellation,
        },
      },
    })),

  setDentalCancellationReasonCode: (cancellationReasonCode) =>
    set((state) => ({
      dental: {
        ...state.dental,
        cancellation: {
          ...state.dental.cancellation,
          reasonCode: cancellationReasonCode,
        },
      },
    })),

  setDentalCancellationReasons: (cancellationReasons) =>
    set((state) => ({
      dental: {
        ...state.dental,
        cancellation: {
          ...state.dental.cancellation,
          reasons: cancellationReasons,
        },
      },
    })),

  setDentalCancellationTerms: (isTermsAcnConditionsAccepted) =>
    set((state) => ({
      dental: {
        ...state.dental,
        cancellation: {
          ...state.dental.cancellation,
          isTermsAndConditionsAccepted: isTermsAcnConditionsAccepted,
        },
      },
    })),

  setDentalCancellationReceipt: (receipt) =>
    set((state) => ({
      dental: {
        ...state.dental,
        cancellation: { ...state.dental.cancellation, receipt: receipt },
      },
    })),

  resetDental: () => set(() => ({ dental: INITIAL_STATE })),
});

export default createDentalSlice;
