export type ErrorState = {
  error: ErrorObject;
} & ErrorActions;

export interface ErrorObject {
  hasError: boolean;
  status?: string | number;
  errorResponse: ErrorResponse;
  errorInstance: Error;
  isDetailedError: boolean;
}

export interface ErrorActions {
  setHasError: (hasError: boolean) => void;
  setStatus: (status: string | number) => void;
  setErrorResponse: (errorResponse: ErrorResponse) => void;
  setErrorInstance: (errorInstance: Error) => void;
  setIsDetailedError: (isDetailedError: boolean) => void;
  resetError: () => void;
}

export interface ErrorResponse {
  totalErrors: number;
  errors: Array<IError>;
  logref?: number;
}

export interface IError {
  readonly code: string;
  readonly message: string;
}

export enum ErrorCodes {
  DEFAULT_ERROR = 'default_error',
  CONFIG_NOT_FOUND = 'config_not_found',
  PARAMETER_ABSENT = 'parameter_absent',
}

export enum ErrorMessages {
  DEFAULT_ERROR = 'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
  CAN_NOT_SHOW_PRODUCT = 'Não foi possível encontrar seus produtos. Por favor tente novamente mais tarde.',
}

export const INITIAL_STATE: ErrorObject = {
  hasError: false,
  errorResponse: {
    totalErrors: 1,
    errors: [
      {
        code: ErrorCodes.DEFAULT_ERROR,
        message: ErrorMessages.DEFAULT_ERROR,
      },
    ],
  },
  errorInstance: new Error(),
  isDetailedError: false,
};
