import React from 'react';
import { RoutesInfo } from 'routes/types';
import { Route } from 'wouter';

const AssistancesCancellation = React.lazy(
  () => import('../pages/cancellation'),
);
const AssistancesCancellationReceipt = React.lazy(
  () => import('../pages/cancellationReceipt'),
);
const AssistancesCancellationRevision = React.lazy(
  () => import('../pages/cancellationRevision'),
);
const AssistancesCancellationSurvey = React.lazy(
  () => import('../pages/cancellationSurvey'),
);

export const AssistancesRoutesInfo: Record<string, RoutesInfo> = {
  cancelAssistance: { path: '/assistance/cancellation' },
  surveyAssitance: { path: '/assistance/cancellation/survey' },
  revisionAssistance: { path: '/assistance/cancellation/revision' },
  receiptAssistance: { path: '/assistance/cancellation/receipt' },
};

export const AssistancesModule = () => (
  <>
    <Route
      path={AssistancesRoutesInfo.cancelAssistance.path}
      component={AssistancesCancellation}
    />
    <Route
      path={AssistancesRoutesInfo.surveyAssitance.path}
      component={AssistancesCancellationSurvey}
    />
    <Route
      path={AssistancesRoutesInfo.revisionAssistance.path}
      component={AssistancesCancellationRevision}
    />
    <Route
      path={AssistancesRoutesInfo.receiptAssistance.path}
      component={AssistancesCancellationReceipt}
    />
  </>
);
