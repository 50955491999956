import React, { FC, Fragment, FunctionComponent } from 'react';
import { Skeleton } from '@interco/inter-ui/components/Skeleton';
import Separator from '../../separatorSection';
import { Flex } from 'common/styles/commons';
import * as S from './styles';

const AfterSaleSkeleton: FC = () => {
  const NavBarSkeleton: FunctionComponent = () => (
    <Flex
      height="56px"
      margin="0 0 32px"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      gap="16px"
    >
      <Skeleton
        width="24px"
        height="24px"
        style={{ minWidth: '24px', borderRadius: '6px' }}
      />
      <Skeleton width="14.5rem" height="10px" style={{ borderRadius: '3px' }} />
      <Skeleton
        width="24px"
        height="24px"
        style={{ minWidth: '24px', borderRadius: '6px' }}
      />
    </Flex>
  );

  const HubSkeleton: FunctionComponent = () => (
    <Flex margin="0 35px 3px 0">
      <Skeleton
        width="48px"
        height="48px"
        variant="circle"
        style={{ minWidth: '48px', margin: '0 0 11px 0' }}
      />
      <Skeleton
        width="32px"
        height="8px"
        style={{ margin: '0 auto 2px', borderRadius: '3px' }}
      />
      <Skeleton
        width="42px"
        height="8px"
        style={{ margin: '0 auto', borderRadius: '3px' }}
      />
    </Flex>
  );

  const AccordionSkeleton: FunctionComponent = () => (
    <Flex flexDirection="row" margin="0 0 3px 0" justifyContent="space-between">
      <Skeleton
        width="77%"
        height="10px"
        style={{ minWidth: '262px', margin: '0 26px 0 0', borderRadius: '3px' }}
      />
      <Skeleton
        width="16px"
        height="10px"
        style={{ minWidth: '16px', borderRadius: '6px' }}
      />
    </Flex>
  );

  return (
    <S.Container data-testid="afterSale-skeleton">
      <NavBarSkeleton />
      <Skeleton
        width="100%"
        height="222px"
        style={{ margin: '0 0 24px 0', borderRadius: '8px' }}
      />
      <Flex flexDirection="row">
        {Array.from({ length: 3 }).map((_value, index) => (
          <HubSkeleton key={`key-${index}`} />
        ))}
      </Flex>

      <Separator fullWidth height="8px" />
      <Skeleton
        width="89px"
        height="16px"
        style={{ margin: '3px 0 29px 0', borderRadius: '3px' }}
      />
      <Flex margin="0 0 3px 0">
        {Array.from({ length: 3 }).map((_value, index) => (
          <Fragment key={`key-${index}`}>
            <AccordionSkeleton />
            {index !== 2 && (
              <Separator fullWidth height="1px" margin="23px 0 23px 0" />
            )}
          </Fragment>
        ))}
      </Flex>

      <Separator fullWidth height="8px" />
      <Skeleton width="100%" height="5.25rem" style={{ borderRadius: '8px' }} />
    </S.Container>
  );
};

export default AfterSaleSkeleton;
