export type DentalState = {
  dental: DentalObject;
} & DentalActions;

export interface DentalObject {
  code: string;
  proposalNumber: string;
  planType: string;
  productType: string;
  planPrice: string;
  paymentMeans: string;
  productId: string;
  cancellation: DentalCancellationFlow;
}

export interface DentalActions {
  setDentalObject: (dental: DentalObject) => void;
  setDentalCancellation: (dentalCancellation: DentalCancellationFlow) => void;
  setDentalCancellationReasonCode: (cancellationReasonCode: number) => void;
  setDentalCancellationReasons: (
    cancellationReasons: DentalCancellationReason[],
  ) => void;
  setDentalCancellationTerms: (isTermsAcnConditionsAccepted: boolean) => void;
  setDentalCancellationReceipt: (receipt: DentalCancellationResponse) => void;
  resetDental: () => void;
}

export interface DentalCancellationResponse {
  message: string;
}

export interface DentalCancellationReason {
  Code: number;
  Description: string;
}

export interface DentalCancellationFlow {
  isTermsAndConditionsAccepted: boolean;
  reasonCode: number;
  reasons: DentalCancellationReason[];
  receipt: DentalCancellationResponse;
}

export const INITIAL_STATE: DentalObject = {
  code: '',
  proposalNumber: '',
  productType: '',
  productId: '',
  planType: '',
  planPrice: '',
  paymentMeans: '',
  cancellation: {
    isTermsAndConditionsAccepted: false,
    reasonCode: 0,
    reasons: [],
    receipt: {
      message: '',
    },
  },
};
