import { styled } from '@linaria/react';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 24px;
  width: 100%;
  z-index: var(--app-bar-z-index);
  background-color: var(--neutral-theme);
`;
