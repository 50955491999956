import { StateCreator } from 'zustand';
import { QueryParamsState, INITIAL_STATE } from './types';

const createQueryParamsSlice: StateCreator<
  QueryParamsState,
  [],
  [],
  QueryParamsState
> = (set) => ({
  queryParams: INITIAL_STATE,
  setPage: (page) =>
    set((state) => ({ queryParams: { ...state.queryParams, page } })),
  setProductId: (productId) =>
    set((state) => ({ queryParams: { ...state.queryParams, productId } })),
  setProductName: (productName) =>
    set((state) => ({ queryParams: { ...state.queryParams, productName } })),
  setMock: (mock) =>
    set((state) => ({ queryParams: { ...state.queryParams, mock } })),
  setMockContaCorrente: (mockContaCorrente) =>
    set((state) => ({
      queryParams: { ...state.queryParams, mockContaCorrente },
    })),
  setMockApiKey: (mockApiKey) =>
    set((state) => ({ queryParams: { ...state.queryParams, mockApiKey } })),
  resetQueryParams: () => set(() => ({ queryParams: INITIAL_STATE })),
});

export default createQueryParamsSlice;
