import { BottomSheetState } from './bottomSheet/types';
import create from 'zustand';
import createErrorSlice from './error';
import { ErrorState } from './error/types';
import createLoadingSlice from './loading';
import { LoadingState } from './loading/types';
import createNavbarSlice from './navbar';
import { NavbarState } from './navbar/types';
import createQueryParamsSlice from './queryParams';
import { QueryParamsState } from './queryParams/types';
import createUserInfoSlice from './user';
import { UserInfoState } from './user/types';
import createBottomSheetSlice from './bottomSheet';
import { AfterSaleState } from './afterSaleConfig/types';
import createAfterSaleSlice from './afterSaleConfig';
import { PageTrasitionState } from './pageTransitionAnimation/type';
import createPageTransitionSlice from './pageTransitionAnimation';
import createVoucherTravelSlice from 'store/travel';
import { VoucherTravelState } from 'store/travel/type';
import createRouterStateSlice from './routerState';
import { RouterState } from './routerState/type';
import createAffinitesSlice from './affinities';
import { AffinitiesState } from './affinities/types';
import { AssistancesState } from './assistances/types';
import createAssistancesSlice from './assistances';
import { DarkModeState } from './darkMode/types';
import createDarkModeSlice from './darkMode';
import { InsuracesState } from './insurences/types';
import createInsurancesSlice from './insurences';
import { GadgetState } from './gadget/types';
import createGadgetSlice from './gadget';
import { DentalState } from './dental/types';
import createDentalSlice from './dental';
import { LifeticketState } from './lifeticket/types';
import createLifeticketSlice from './lifeticket';

type RootState = NavbarState &
  ErrorState &
  LoadingState &
  QueryParamsState &
  UserInfoState &
  BottomSheetState &
  AfterSaleState &
  PageTrasitionState &
  RouterState &
  VoucherTravelState &
  AffinitiesState &
  AssistancesState &
  DarkModeState &
  InsuracesState &
  GadgetState &
  LifeticketState &
  DentalState;

const useBoundState = create<RootState>()((...a) => ({
  ...createErrorSlice(...a),
  ...createLoadingSlice(...a),
  ...createNavbarSlice(...a),
  ...createQueryParamsSlice(...a),
  ...createUserInfoSlice(...a),
  ...createBottomSheetSlice(...a),
  ...createAfterSaleSlice(...a),
  ...createPageTransitionSlice(...a),
  ...createRouterStateSlice(...a),
  ...createVoucherTravelSlice(...a),
  ...createAffinitesSlice(...a),
  ...createAssistancesSlice(...a),
  ...createDarkModeSlice(...a),
  ...createInsurancesSlice(...a),
  ...createGadgetSlice(...a),
  ...createDentalSlice(...a),
  ...createLifeticketSlice(...a),
}));

export default useBoundState;
