import { BottomSheetSectionType } from '../../common/sections/types';

export type BottomSheetState = {
  bottomSheet: BottomSheetObject;
} & BottomSheetActions;

export interface BottomSheetObject {
  show: boolean;
  payload: BottomSheetSectionType | JSX.Element;
}

export interface BottomSheetActions {
  setShowBottomSheet: (show: boolean) => void;
  setBottomSheetPayload: (
    payload: BottomSheetSectionType | JSX.Element,
  ) => void;
  resetBottomSheet: () => void;
}

export const INITIAL_STATE: BottomSheetObject = {
  show: false,
  payload: { contents: [] },
};
