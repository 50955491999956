import { initTheme, Theme } from '@interco/inter-ui';
import OnWebviewInit from 'common/components/onWebviewInit';
import Overlay from 'common/components/overlay';
import { CacheService } from 'common/services/cacheService';
import { EnvVariableService } from 'common/services/envService';
import { EnvVariableKeys } from 'common/services/envService/types';
import { MonitoringService } from 'common/services/monitoringService';
import { WouterService } from 'common/services/wouterService';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import RoutesComponent from 'routes';
import useBoundState from 'store';
import { useLocation } from 'wouter';
import { ErrorFallback, ErrorObserver } from './common/components';
import { useLink } from 'common/hooks/useLink';

function App() {
  const addLink = useLink();

  const resetErrorState = useBoundState((state) => state.resetError);

  const setLocation = useLocation()[1];

  const appDbName = EnvVariableService.getVariable(EnvVariableKeys.APP_DB_NAME);

  const { isDarkMode } = useBoundState((state) => state.darkMode);

  WouterService.initWouter(setLocation);

  const resetError = () => {
    resetErrorState();
  };

  useEffect(() => {
    addLink('https://cdn.bancointer.com.br/inter-ui/4.x/latest/inter-ui.css');
    addLink('https://fonts.googleapis.com/css?family=Inter&display=swap');
    addLink('https://fonts.googleapis.com/css?family=Sora&display=swap');

    initTheme(Theme.PF, isDarkMode);

    const initCacheService = async () => {
      CacheService.initCacheService(appDbName);

      await CacheService.clearExpiredItems();
    };

    initCacheService().catch((e) => {
      MonitoringService.noticeError(e, {
        errorCodeRef: 'App.useEffect.initCacheSercive',
      });
    });
  });

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={resetError}
      onError={(error, info) =>
        MonitoringService.noticeError(error, {
          componentStackTrace: info.componentStack,
        })
      }
    >
      <Overlay />
      <RoutesComponent />
      <OnWebviewInit />
      <ErrorObserver />
    </ErrorBoundary>
  );
}

export default App;
