import { StateCreator } from 'zustand';
import { INITIAL_STATE, VoucherTravelState } from './type';

const createVoucherTravelSlice: StateCreator<
  VoucherTravelState,
  [],
  [],
  VoucherTravelState
> = (set) => ({
  voucherTravel: INITIAL_STATE,
  setVoucherTravel: (travelers) =>
    set((state) => ({
      voucherTravel: { ...state.voucherTravel, travelers },
    })),
  setVoucherPlanType: (planType) => {
    set((state) => ({
      voucherTravel: { ...state.voucherTravel, planType },
    }));
  },
  resetVoucherTravel: () => set(() => ({ voucherTravel: INITIAL_STATE })),
});

export default createVoucherTravelSlice;
