import React from 'react';
import { RoutesInfo } from 'routes/types';
import { Redirect, Route } from 'wouter';
import { useDefaultRoute } from './useDefaultRoute';

const AfterSale = React.lazy(() => import('../pages/afterSale'));
const AfterSalePreLoading = React.lazy(
  () => import('../pages/afterSalePreLoading'),
);
const Insurances = React.lazy(() => import('../pages/insurances'));

export const MainRoutesInfo: Record<string, RoutesInfo> = {
  afterSalePreLoading: { path: '/afterSalePreLoading' },
  afterSale: { path: '/afterSale' },
  insurances: { path: '/insurances' },
};

export const MainModule = () => {
  const defaultRoute = useDefaultRoute();

  return (
    <>
      <Route path="/" component={() => <Redirect to={defaultRoute} />} />
      <Route
        path={MainRoutesInfo.afterSalePreLoading.path}
        component={AfterSalePreLoading}
      />
      <Route path={MainRoutesInfo.afterSale.path} component={AfterSale} />
      <Route path={MainRoutesInfo.insurances.path} component={Insurances} />
    </>
  );
};
