import { StateCreator } from 'zustand';
import { INITIAL_STATE, BottomSheetState } from './types';

const createBottomSheetSlice: StateCreator<
  BottomSheetState,
  [],
  [],
  BottomSheetState
> = (set) => ({
  bottomSheet: INITIAL_STATE,
  setShowBottomSheet: (show) =>
    set((state) => ({ bottomSheet: { ...state.bottomSheet, show } })),
  setBottomSheetPayload: (payload) =>
    set((state) => ({ bottomSheet: { ...state.bottomSheet, payload } })),
  resetBottomSheet: () => set(() => ({ bottomSheet: INITIAL_STATE })),
});

export default createBottomSheetSlice;
