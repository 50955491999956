import { StateCreator } from 'zustand';
import { LifeticketState, INITIAL_STATE } from './types';

const createLifeticketSlice: StateCreator<
  LifeticketState,
  [],
  [],
  LifeticketState
> = (set) => ({
  lifeticket: INITIAL_STATE,
  setLifeticketObject: (lifeticket) => set(() => ({ lifeticket: lifeticket })),
  setLifeticktCancellation: (lifeticketCancellation) =>
    set((state) => ({
      lifeticket: {
        ...state.lifeticket,
        cancellation: {
          ...state.lifeticket.cancellation,
          cancellation: lifeticketCancellation,
        },
      },
    })),
  setLifeticketCancellationReceipt: (receipt) =>
    set((state) => ({
      lifeticket: {
        ...state.lifeticket,
        cancellation: { ...state.lifeticket.cancellation, receipt: receipt },
      },
    })),
  resetLifeticket: () => set(() => ({ lifeticket: INITIAL_STATE })),
});

export default createLifeticketSlice;
