import React from 'react';
import { RoutesInfo } from 'routes/types';
import { Route } from 'wouter';
import LifeticketCancellationReceipt from '../pages/cancellationReceipt';

const Lifeticket = React.lazy(() => import('../pages/cancellation'));

export const LifeticketRoutesInfo: Record<string, RoutesInfo> = {
  cancelLifeticket: { path: '/lifeticket/cancellation' },
  cancelationReceipt: { path: '/lifeticket/cancellation/receipt' },
};

export const LifeticketModule = () => (
  <>
    <Route
      path={LifeticketRoutesInfo.cancelLifeticket.path}
      component={Lifeticket}
    />
    <Route
      path={LifeticketRoutesInfo.cancelationReceipt.path}
      component={LifeticketCancellationReceipt}
    />
  </>
);
